import { createSlice } from "@reduxjs/toolkit";

const taskSlice = createSlice({
    name:"task",
    initialState:{
        tasks:{
            allTask: null,
            isFetching:false,
            error:false
        },
        task:{
            progress:null,
            isFetching:false,
            error:false
        }
    },
    reducers:{
        getTasksStart:(state)=>{
            state.tasks.isFetching =true;
        },
        getTasksSuccess:(state,action)=>{
            state.tasks.isFetching = false;
            state.tasks.allTask = action.payload;
        },
        getTasksFailed:(state)=>{
            state.tasks.isFetching = false;
            state.tasks.error = true;
        },
        getCheckStart:(state)=>{
            state.task.isFetching = true;
        },
        getCheckSuccess:(state,action)=>{
            state.task.isFetching = false;
            state.task.progress = action.payload;
        },
        getCheckFailed:(state)=>{
            state.task.isFetching = false;
            state.task.error = true;
        },
    }
})
export const {
    getTasksStart,
    getTasksSuccess,
    getTasksFailed,
    getCheckStart,
    getCheckSuccess,
    getCheckFailed,
} = taskSlice.actions;
export default taskSlice.reducer;