import { useState } from "react";
import "./register.css";
import { registerUser } from "../../redux/apiRequest";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const Register = () => {
    const [username, setUsename] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleRegister = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const newUser = {
                username: username,
                password: password
            };
            registerUser(newUser, dispatch, navigate,handleRegisterError)
        }
    };
    const handleRegisterError = (error) => {
        setErrors({ username: error.message });
    };
    const validateForm = () => {
        const errors = {};

        if (username.trim() === '') {
            errors.username = 'Username is required';
        } else if (username.length < 8 || username.length > 20) {
            errors.username = 'Username must be 8-20 characters long';
        }

        if (password.trim() === '') {
            errors.password = 'Password is required';
        } else if (password.length < 8 || password.length > 24) {
            errors.password = 'Password must be 8-24 characters long';
        }
        if (confirmPassword.trim() === '') {
            errors.confirmPassword = 'Confirm Password is required';
        } else if (confirmPassword !== password) {
            errors.confirmPassword = 'Passwords do not match';
        }
        setErrors(errors);

        return Object.keys(errors).length === 0;
    };
    return (
        <section className="register-section">
            <div className="register-container">
            <div className="register-title"> Sign up </div>
            <form className="form-register" onSubmit={handleRegister}>
                {/* <label>EMAIL</label>
                <input type="text" placeholder="Enter your email" /> */}
                <label>USERNAME</label>
                <input
                    type="text"
                    placeholder="Enter your username"
                    onChange={(e) => setUsename(e.target.value)}
                />
                <span className="register-error">{errors.username}</span>
                <label>PASSWORD</label>
                <input
                    type="password"
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <span className="register-error">{errors.password}</span>
                <label>CONFIRM PASSWORD</label>
                <input
                    type="password"
                    placeholder="Enter your password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <span className="register-error">{errors.confirmPassword}</span>
                <button type="submit"> Create account </button>
            </form>
            </div>
        </section>

    );
}

export default Register;