import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createAxios } from "../../createInstance";
import "./home.css";
import { checkTask, createTask, deleteTask, getAllTasks } from "../../redux/apiRequest";
import { loginSuccess } from "../../redux/authSlice";

const HomePage = () => {
  //DUMMY DATA
  const user = useSelector((state) => state.auth.login?.currentUser);
  let taskList = useSelector((state) => state.task.tasks?.allTask);
  const dispatch = useDispatch();
  const [taskname, setTaskName] = useState("");
  let axiosJWT = createAxios(user,dispatch,loginSuccess);

  const handleClick = async (e, progress, taskid) => {
    const check = "checked";
    const uncheck = "";
    if (progress !== "checked") {
      const newCheck = { progress: check }
      await checkTask(user?.accessToken,dispatch, taskid, newCheck, axiosJWT)
      await getAllTasks(user?.accessToken, dispatch, user?._id, axiosJWT)
    }
    else {
      const newCheck = { progress: uncheck }
      await checkTask(user?.accessToken,dispatch, taskid, newCheck, axiosJWT)
      await getAllTasks(user?.accessToken, dispatch, user?._id, axiosJWT)
    }
  };
  const handleDelete = async () => {
    await deleteTask(user?.accessToken,user?._id, axiosJWT)
    await getAllTasks(user?.accessToken, dispatch, user?._id, axiosJWT)
  }
  const handleAddTask = async () => {
    const newTask = { taskname: taskname };
    await createTask(user?.accessToken,user?._id, newTask,axiosJWT);
    await getAllTasks(user?.accessToken, dispatch, user?._id, axiosJWT);
    await setTaskName("");
  }

  useEffect(() => {
    if (user?.accessToken) {
      getAllTasks(user?.accessToken, dispatch, user?._id, axiosJWT)
    }
  }, []);

  if (!user) {
    return (
      <div className="not-user">
        <div className="not-user-body">
          <h1>WELCOME TO TODOAPP</h1>
          <div className="not-user-content">
            <h1>Please</h1><Link className="not-user-link" to="/login">Login</Link>
          </div>
        </div>
      </div>
    );
  }
  return (
    <main className="main-container">
      <div className="home-container">
        <div className="create-task">
          <input className="input-task" type="text"
            id="taskname"
            name="taskname"
            value={taskname}
            onChange={(e) => setTaskName(e.target.value)}
            placeholder="Enter your Task Name" />
          <button className="button-task" type="submit" onClick={handleAddTask}> Add Task </button>
        </div>

        <div className="home-title">TASK LIST</div>
        <div className="home-userlist">
          {taskList?.map((task) => {
            const isActProgress = task.progress === "checked" ? "isActProgress" : "";
            return (
              <div className="user-container">
                <div className={`home-user ${isActProgress}`}>{task.taskname}</div>
                <label class="container-checkbox">
                  <input className="input-checkbox" type="checkbox" name="progress" id={task._id} checked={task.progress} onClick={(e) => { handleClick(e, task.progress, task._id) }} />
                  <span class="checkmark"></span>
                </label>
              </div>
            );
          })}
        </div>
        <div className="deleTasks">
          <button type="submit" onClick={handleDelete}>Delete all completed work</button>
        </div>
      </div>
    </main>
  );
};

export default HomePage;
