
import axios from "axios";
import { RegisterFailed, RegisterStart, RegisterSuccess, loginFailed, loginStart, loginSuccess, logoutFailed, logoutStart, logoutSuccess } from "./authSlice";
import { getCheckFailed, getCheckStart, getCheckSuccess, getTasksFailed, getTasksStart, getTasksSuccess } from "./taskSlice";
axios.defaults.baseURL = 'https://thienho.gcalls.vn/';
export const loginUser = async (user, dispatch, navigate,errorCallback) => {
    dispatch(loginStart());
    try {
        const res = await axios.post("/api/auth/login", user);
        dispatch(loginSuccess(res.data));
        navigate("/");
    }
    catch (error) {
        if (error.response && error.response.data && error.response.data.message !== "") {
            errorCallback(new Error("UserName or password is incorrect"));
            dispatch(loginFailed());
        }
    }
}
export const logOut = async (dispatch, id, navigate, accessToken, axiosJWT) => {
    dispatch(logoutStart());
    try {
        await axiosJWT.post("/api/auth/logout", id, {
            headers: { token: `Bearer ${accessToken}` },
        });
        dispatch(logoutSuccess());
        navigate("/login");
    }
    catch (err) {
        dispatch(logoutFailed());
    }
}


export const registerUser = async (user, dispatch, navigate,errorCallback) => {
    dispatch(RegisterStart());
    try {
        await axios.post("/api/auth/register", user)
        dispatch(RegisterSuccess());
        navigate("/login");
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message !== "") {
            errorCallback(new Error("Username already exists"));
            dispatch(RegisterFailed(error));
        }
    }
}
export const getAllTasks = async (accessToken, dispatch, id, axiosJWT) => {
    dispatch(getTasksStart());
    try {
        const res = await axiosJWT.get("/api/" + id, {
            headers: { token: `Bearer ${accessToken}` },
        });
        dispatch(getTasksSuccess(res.data));
    }
    catch (err) {
        dispatch(getTasksFailed());
    }
}
export const checkTask = async (accessToken, dispatch, id, progress, axiosJWT) => {
    dispatch(getCheckStart());
    try {
        const res = await axiosJWT.patch("/api/task/" + id, progress, {
            headers: { token: `Bearer ${accessToken}` },
        });
        dispatch(getCheckSuccess(res.data));
    } catch (error) {
        dispatch(getCheckFailed());
    }
}
export const deleteTask = async (accessToken,id,axiosJWT) => {
    try {
        await axiosJWT.delete("/api/task/" + id, {
            headers: { token: `Bearer ${accessToken}` },
        });
    } catch (error) {
        console.log(error);
    }
}

export const createTask = async (accessToken,id, taskname, axiosJWT) => {
    try {
        await axiosJWT.post("/api/" + id, taskname, {
            headers: { token: `Bearer ${accessToken}` },
        });
    } catch (error) {
        console.log(error);
    }
}